@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

:root {
  --bs-body-bg: #f5f5f9;
  --bs-body-bg-rgb: 245, 245, 249;
  --bs-body-color: #000a2f;
  --bs-body-color-rgb: 0, 10, 47;
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.53;
  --bs-font-sans-serif: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --bs-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;

  --bs-primary: #1c4d6b;
  --bs-primary-rgb: 28, 77, 107;
  --bs-primary-contrast: #fff;
  --bs-primary-shade: #1e2e42;
  --bs-primary-shade-rgb: 30, 46, 66;
  --bs-primary-tint: #c0d8e7;
  --bs-primary-tint-rgb: 192, 216, 231;

  --bs-secondary: #ebeef0;
  --bs-secondary-rgb: 235, 238, 240;
  --bs-secondary-contrast: #8592a3;
  --bs-secondary-shade: #8592a3;
  --bs-secondary-shade-rgb: 133, 146, 163;
  --bs-secondary-shade-contrast: #fff;
  --bs-secondary-tint: #f1f3f4;
  --bs-secondary-tint-rgb: 241, 243, 244;

  --bs-danger: #dc3545;
  --bs-danger-rgb: 220, 53, 69;
  --bs-danger-contrast: #fff;
  --bs-danger-shade: #d12435;
  --bs-danger-shade-rgb: 209, 36, 53;
  --bs-danger-shade-contrast: #fff;
  --bs-danger-tint: #f8d7da;
  --bs-danger-tint-rgb: 248, 215, 218;

  --bs-success: #198754;
  --bs-success-rgb: 25, 135, 84;
  --bs-success-contrast: #fff;
  --bs-success-shade: #16794c;
  --bs-success-shade-rgb: 22, 121, 76;
  --bs-success-shade-contrast: #fff;
  --bs-success-tint: #c1f4dc;
  --bs-success-tint-rgb: 193, 244, 220;

  --main-menu-width: 240px;
  --main-menu-width-collapsed: 4.5rem;
  --main-menu-bg: #fff;
  --main-menu-color: #000a2f;

  --bs-link-color: var(--bs-primary);
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-link-hover-color: var(--bs-primary-shade);
  --bs-link-hover-color-rgb: var(--bs-primary-shade-rgb);

  --bs-box-bg: #fff;
  --bs-box-bg-rgb: 255, 255, 255;
  --bs-box-color: #697a8d;
  --bs-box-color-rgb: 105, 122, 141;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}
.offcanvas {
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-box-shadow: 0 0.125rem 0.375rem 0 rgba(161, 172, 184, 0.12);

  box-shadow: var(--bs-offcanvas-box-shadow);
}
.offcanvas-backdrop {
  --bs-offcanvas-backdrop-bg: rgba(var(--bs-primary-rgb), 0.2);
  background-color: var(--bs-offcanvas-backdrop-bg);
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 3.25rem;
}

.navbar {
  --bs-navbar-toggler-focus-width: 0;
}

.centered-content {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.has-login-background {
  .container-xxl {
    --bs-gutter-x: 0;
    max-width: none;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.list-group-header {
  font-size: 0.8rem;
  font-weight: bold;
  opacity: 0.7;
}
.list-group {
  --bs-list-group-bg: transparent;
  --bs-list-group-disabled-bg: transparent;
  --bs-list-group-color: inherit;
  --bs-list-group-action-hover-color: var(--bs-primary);
  --bs-list-group-action-hover-bg: var(--bs-primary-tint);

  .list-group-item {
    .form-check-input {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.btn {
  transition: all 0.2s ease-in-out;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.4375rem;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.53;

  &.btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.875rem;
  }

  &:hover,
  &:focus {
    transform: translateY(-1px) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.btn-primary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-color: var(--bs-primary-contrast);

  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-btn-disabled-color: var(--bs-primary-contrast);

  --bs-btn-hover-bg: var(--bs-primary-shade);
  --bs-btn-hover-border-color: var(--bs-primary-shade);
  --bs-btn-hover-color: var(--bs-primary-contrast);

  --bs-btn-focus-bg: var(--bs-primary-shade);
  --bs-btn-focus-border-color: var(--bs-primary-shade);
  --bs-btn-focus-color: var(--bs-primary-contrast);

  --bs-btn-active-bg: var(--bs-primary-shade);
  --bs-btn-active-border-color: var(--bs-primary-shade);
  --bs-btn-active-color: var(--bs-primary-contrast);

  box-shadow: 0 0.125rem 0.25rem 0 rgba(var(--bs-primary-rgb), 0.4);
}

.btn-outline-primary {
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-color: var(--bs-primary);

  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-btn-disabled-color: var(--bs-primary);

  --bs-btn-hover-border-color: var(--bs-primary-shade);
  --bs-btn-hover-color: var(--bs-primary-contrast);
  --bs-btn-hover-bg: var(--bs-primary);

  --bs-btn-focus-border-color: var(--bs-primary-shade);
  --bs-btn-focus-color: var(--bs-primary-contrast);

  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-color: var(--bs-primary-contrast);
}

.btn-secondary {
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-color: var(--bs-secondary-contrast);

  --bs-btn-disabled-bg: var(--bs-secondary);
  --bs-btn-disabled-border-color: var(--bs-secondary);
  --bs-btn-disabled-color: var(--bs-secondary-contrast);

  --bs-btn-hover-bg: var(--bs-secondary-shade);
  --bs-btn-hover-border-color: var(--bs-secondary-shade);
  --bs-btn-hover-color: var(--bs-secondary-shade-contrast);

  --bs-btn-focus-bg: var(--bs-secondary-shade);
  --bs-btn-focus-border-color: var(--bs-secondary-shade);
  --bs-btn-focus-color: var(--bs-secondary-shade-contrast);

  --bs-btn-active-bg: var(--bs-secondary-shade);
  --bs-btn-active-border-color: var(--bs-secondary-shade);
  --bs-btn-active-color: var(--bs-secondary-shade-contrast);

  &:hover,
  &:focus {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(133, 146, 163, 0.4);
  }
}

.btn-outline-secondary {
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-color: var(--bs-secondary);

  --bs-btn-disabled-border-color: var(--bs-secondary);
  --bs-btn-disabled-color: var(--bs-secondary);

  --bs-btn-hover-border-color: var(--bs-secondary-shade);
  --bs-btn-hover-color: var(--bs-secondary-contrast);
  --bs-btn-hover-bg: var(--bs-secondary);

  --bs-btn-focus-border-color: var(--bs-secondary-shade);
  --bs-btn-focus-color: var(--bs-secondary-contrast);

  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-active-color: var(--bs-secondary-contrast);
}

.btn-danger {
  --bs-btn-bg: var(--bs-danger);
  --bs-btn-border-color: var(--bs-danger);
  --bs-btn-color: var(--bs-danger-contrast);

  --bs-btn-disabled-bg: var(--bs-danger);
  --bs-btn-disabled-border-color: var(--bs-danger);
  --bs-btn-disabled-color: var(--bs-danger-contrast);

  --bs-btn-hover-bg: var(--bs-danger-shade);
  --bs-btn-hover-border-color: var(--bs-danger-shade);
  --bs-btn-hover-color: var(--bs-danger-contrast);

  --bs-btn-focus-bg: var(--bs-danger-shade);
  --bs-btn-focus-border-color: var(--bs-danger-shade);
  --bs-btn-focus-color: var(--bs-danger-contrast);

  --bs-btn-active-bg: var(--bs-danger-shade);
  --bs-btn-active-border-color: var(--bs-danger-shade);
  --bs-btn-active-color: var(--bs-danger-contrast);

  box-shadow: 0 0.125rem 0.25rem 0 rgba(var(--bs-danger-rgb), 0.4);
}

.btn-outline-danger {
  --bs-btn-border-color: var(--bs-danger);
  --bs-btn-color: var(--bs-danger);

  --bs-btn-disabled-border-color: var(--bs-danger);
  --bs-btn-disabled-color: var(--bs-danger);

  --bs-btn-hover-border-color: var(--bs-danger-shade);
  --bs-btn-hover-color: var(--bs-danger-contrast);
  --bs-btn-hover-bg: var(--bs-danger);

  --bs-btn-focus-border-color: var(--bs-danger-shade);
  --bs-btn-focus-color: var(--bs-danger-contrast);

  --bs-btn-active-bg: var(--bs-danger);
  --bs-btn-active-border-color: var(--bs-danger);
  --bs-btn-active-color: var(--bs-danger-contrast);
}

.btn-success {
  --bs-btn-bg: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-color: var(--bs-success-contrast);

  --bs-btn-disabled-bg: var(--bs-success);
  --bs-btn-disabled-border-color: var(--bs-success);
  --bs-btn-disabled-color: var(--bs-success-contrast);

  --bs-btn-hover-bg: var(--bs-success-shade);
  --bs-btn-hover-border-color: var(--bs-success-shade);
  --bs-btn-hover-color: var(--bs-success-contrast);

  --bs-btn-focus-bg: var(--bs-success-shade);
  --bs-btn-focus-border-color: var(--bs-success-shade);
  --bs-btn-focus-color: var(--bs-success-contrast);

  --bs-btn-active-bg: var(--bs-success-shade);
  --bs-btn-active-border-color: var(--bs-success-shade);
  --bs-btn-active-color: var(--bs-success-contrast);

  box-shadow: 0 0.125rem 0.25rem 0 rgba(var(--bs-success-rgb), 0.4);
}

.btn-outline-success {
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-color: var(--bs-success);

  --bs-btn-disabled-border-color: var(--bs-success);
  --bs-btn-disabled-color: var(--bs-success);

  --bs-btn-hover-border-color: var(--bs-success-shade);
  --bs-btn-hover-color: var(--bs-success-contrast);
  --bs-btn-hover-bg: var(--bs-success);

  --bs-btn-focus-border-color: var(--bs-success-shade);
  --bs-btn-focus-color: var(--bs-success-contrast);

  --bs-btn-active-bg: var(--bs-success);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-active-color: var(--bs-success-contrast);
}

.btn-warning {
  --bs-btn-bg: var(--bs-warning);
  --bs-btn-border-color: var(--bs-warning);
  --bs-btn-color: var(--bs-warning-contrast);

  --bs-btn-disabled-bg: var(--bs-warning);
  --bs-btn-disabled-border-color: var(--bs-warning);
  --bs-btn-disabled-color: var(--bs-warning-contrast);

  --bs-btn-hover-bg: var(--bs-warning-shade);
  --bs-btn-hover-border-color: var(--bs-warning-shade);
  --bs-btn-hover-color: var(--bs-warning-contrast);

  --bs-btn-focus-bg: var(--bs-warning-shade);
  --bs-btn-focus-border-color: var(--bs-warning-shade);
  --bs-btn-focus-color: var(--bs-warning-contrast);

  --bs-btn-active-bg: var(--bs-warning-shade);
  --bs-btn-active-border-color: var(--bs-warning-shade);
  --bs-btn-active-color: var(--bs-warning-contrast);

  box-shadow: 0 0.125rem 0.25rem 0 rgba(var(--bs-warning-rgb), 0.4);
}

.btn-outline-warning {
  --bs-btn-border-color: var(--bs-warning);
  --bs-btn-color: var(--bs-warning);

  --bs-btn-disabled-border-color: var(--bs-warning);
  --bs-btn-disabled-color: var(--bs-warning);

  --bs-btn-hover-border-color: var(--bs-warning-shade);
  --bs-btn-hover-color: var(--bs-warning-contrast);
  --bs-btn-hover-bg: var(--bs-warning);

  --bs-btn-focus-border-color: var(--bs-warning-shade);
  --bs-btn-focus-color: var(--bs-warning-contrast);

  --bs-btn-active-bg: var(--bs-warning);
  --bs-btn-active-border-color: var(--bs-warning);
  --bs-btn-active-color: var(--bs-warning-contrast);
}

.btn-icon {
  border: none;
  padding: 0;
  color: var(--bs-primary);

  &:hover,
  &.focus,
  &:focus,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    color: var(--bs-primary);
  }
}

.input-group {
  .btn {
    min-width: 46px;

    &:hover,
    &:focus {
      transform: none !important;
    }
  }
}

.input-group-search {
  position: relative;
  align-items: center;

  &.is-searching {
    .input-group-text {
      fa-icon {
        animation: 0.75s linear infinite spinner-border;
      }
    }
  }

  .input-group-text {
    background-color: var(--bs-box-bg);
    color: var(--bs-box-color);
    position: absolute;
    z-index: 999;
    top: 1px;
    left: 0;
    border: 0;
  }
  .form-control {
    padding-left: 42px;
    border-radius: 0.375rem !important;
    background-color: transparent;
  }
}

.form-control {
  &::placeholder {
    opacity: 0.5;
  }
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  margin-left: 0.4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}
.form-check {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 0;

  .form-check-label {
    position: relative;
    top: 2px;
  }
}
.form-check-input {
  --bs-form-check-bg: #fff;
  cursor: pointer;

  &:checked,
  &[type="checkbox"]:indeterminate {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    box-shadow: 0 2px 4px 0 rgba(var(--bs-primary-rgb), 0.4);
  }
  &:focus {
    border-color: var(--bs-primary);
    box-shadow: 0 2px 4px 0 rgba(var(--bs-primary-rgb), 0.4);
  }
  &:active {
    filter: brightness(90%);
  }
}
.form-switch .form-check-input:not(:checked):focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231c4d6b'/%3e%3c/svg%3e");
}

.fake-input {
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  overflow: auto;

  &.disabled {
    background-color: #e9ecef;
  }
  .btn-clear {
    --bs-btn-border-width: 0;
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;
  }

  .fake-input-text {
    min-height: 24px;
  }
}
a,
.fake-link:not([href]) {
  color: var(--bs-primary);

  &:hover {
    color: var(--bs-primary-shade);
  }
}
.fake-link:not([href]) {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.navbar {
  --bs-navbar-toggler-padding-y: 1rem;

  .navbar-toggler {
    border-radius: 0;
    border-color: #607d8b;
  }

  .navbar-nav .nav-link {
    color: #607d8b;

    &:hover,
    &:focus,
    &.active {
      color: var(--bs-primary);
    }
  }
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-primary);
}
.card {
  --bs-card-bg: var(--bs-box-bg);
  --bs-card-color: var(--bs-box-color);
  --bs-card-box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0.875rem;
  --bs-card-cap-padding-y: 16px;
  --bs-card-cap-padding-x: 24px;
  --bs-card-cap-color: var(--bs-body-color);
  --bs-card-border-color: var(--bs-body-bg);

  box-shadow: var(--bs-card-box-shadow);

  &.clickable {
    cursor: pointer;

    --bs-card-bg: var(--bs-primary-tint);
    --bs-card-color: var(--bs-primary);
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;

    &:hover,
    &:focus {
      --bs-card-bg: var(--bs-primary);
      --bs-card-color: var(--bs-primary-contrast);
    }
  }

  .card-header {
    background-color: var(--bs-card-bg);

    .card-title {
      margin-bottom: 0;
      font-size: 1rem;
    }

    .form-control {
      background-color: transparent;

      &:focus:not(.is-invalid) {
        border-color: var(--bs-primary);
        box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
      }
    }
  }
  .card-body {
    &.card-body-no-padding {
      padding: 0;
    }
  }
  fieldset {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    border-radius: var(--bs-card-border-radius);
    border: none;
    padding: 1rem;

    legend {
      text-align: center;
      font-size: 1.2rem;
    }
  }
  .card-footer {
    background-color: var(--bs-card-bg);

    .form-select {
      background-color: var(--bs-card-bg);
    }
  }
  .table-responsive {
    &:not(.no-border) {
      border-top: 1px solid #d9dee3;
    }
    tbody {
      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
  .table {
    margin-bottom: 0;

    --bs-table-bg: transparent;
    --bs-table-color: var(--bs-card-color);
    --bs-table-color-rgb: rgb(var(--bs-card-color-rgb));
  }
  .list-group {
    --bs-list-group-item-padding-x: 24px;
    --bs-list-group-bg: transparent;
  }
}
.table {
  thead {
    th {
      &.sortable {
        cursor: pointer;
      }
      &.is-sorted {
        font-weight: bold;

        &.asc:after {
          content: "▲";
        }
        &.desc:after {
          content: "▼";
        }
        &.asc:after,
        &.desc:after {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: var(--bs-primary);
  color: #fff;
  border-color: var(--bs-primary-tint);

  a {
    color: inherit;
  }
}
.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: var(--bs-primary-shade);
}
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
tr.clickable {
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
.popover {
  max-width: 300px;
}
.nav-pills {
  gap: 5px;
}
.nav-tabs {
  --bs-nav-tabs-link-active-bg: var(--bs-box-bg);
  --bs-nav-tabs-border-width: 0;

  .nav-item.show .nav-link,
  .nav-link.active {
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  }
}
.nav-pills .nav-link {
  --bs-nav-pills-link-hover-bg: rgba(var(--bs-primary-rgb), 0.16);
  --bs-nav-pills-link-hover-color: var(--bs-primary);
  --bs-nav-pills-link-active-color: var(--bs-primary-contrast);
  --bs-nav-pills-link-active-bg: var(--bs-primary);

  &:focus,
  &:hover {
    background-color: var(--bs-nav-pills-link-hover-bg);
  }

  &.active {
    &:focus,
    &:hover {
      color: var(--bs-nav-pills-link-active-color);
      background-color: var(--bs-nav-pills-link-active-bg);
    }
  }
}
#fr-logo {
  display: none;
}

.dropdown-item {
  &.active.disabled,
  &.active:disabled {
    color: #fff;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--bs-primary);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.fl-scrolls {
  background-color: rgba(var(--bs-primary-rgb), 0.3);
}
.fl-scrolls:not([data-orientation]),
.fl-scrolls[data-orientation="horizontal"] {
  min-height: auto;
}

quill-editor {
  .ql-snow {
    background-color: #fff;

    .ql-picker.ql-size .ql-picker-label,
    .ql-picker.ql-size .ql-picker-item {
      &::before {
        content: "Normal";
      }

      &[data-value="small"]::before {
        content: "Petit";
      }
      &[data-value="large"]::before {
        content: "Grand";
      }
      &[data-value="huge"]::before {
        content: "Très grand";
      }
    }
    .ql-tooltip::before {
      content: "Visitez l'URL :";
    }
    .ql-tooltip a.ql-action::after {
      content: "Éditer";
    }
    .ql-tooltip a.ql-remove::before {
      content: "Supprimer";
    }
    .ql-tooltip.ql-editing a.ql-action::after {
      content: "Sauvegarder";
    }
    .ql-tooltip[data-mode="link"]::before {
      content: "Entrer lien :";
    }
    .ql-tooltip[data-mode="formula"]::before {
      content: "Entrer formule:";
    }
    .ql-tooltip[data-mode="video"]::before {
      content: "Entrer vidéo:";
    }
  }
  .ql-editor {
    min-height: 200px;
  }
}

quill-view {
  .ql-editor {
    padding: 0;
  }
  .ql-container {
    font-size: inherit;
  }
}

.form-inline {
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  .form-control,
  .form-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.form-control,
.form-select {
  background-color: var(--bs-box-bg);
  color: var(--bs-box-color);
  position: relative;

  &:focus:not(.is-invalid) {
    background-color: var(--bs-box-bg);
    color: var(--bs-box-color);
    border-color: var(--bs-primary);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
  }
  &[readonly] {
    background-color: var(--bs-secondary-bg);
  }
}

.input-group .btn {
  height: 100%;
}

.wizard-steps {
  text-align: center;
  --bs-border-radius-steps: 5px;
  margin-bottom: 20px;
  list-style: none;
  padding: 0;

  li {
    padding: 10px 15px;
    background-color: #f5f5f5;
    margin-bottom: 1px;

    &.active {
      background-color: var(--bs-primary);
      color: var(--bs-primary-contrast);
    }

    &:first-child {
      border-top-left-radius: var(--bs-border-radius-steps);
      border-top-right-radius: var(--bs-border-radius-steps);
    }
    &:last-child {
      border-bottom-left-radius: var(--bs-border-radius-steps);
      border-bottom-right-radius: var(--bs-border-radius-steps);
    }

    @media (min-width: 768px) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 1px;

      &:first-child {
        border-bottom-left-radius: var(--bs-border-radius-steps);
        border-top-right-radius: 0;
      }
      &:last-child {
        border-top-right-radius: var(--bs-border-radius-steps);
        border-bottom-left-radius: 0;
        margin-right: 0;
      }
    }
  }
}

.tooltip {
  --bs-tooltip-max-width: 450px;
}

.btn-toolbar-actions {
  margin: 20px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.card-with-side-tabs {
  @media (min-width: 768px) {
    display: flex;
    gap: 1.5rem;
  }

  .card-sidebar {
    .btn-toolbar {
      margin: 20px 0;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .nav-pills {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0;
    --bs-nav-link-font-size: 0.7rem;
    gap: 1rem;
    margin-bottom: 0.7rem;

    @media (min-width: 768px) {
      min-width: 200px;
      --bs-nav-link-font-size: 1rem;
      margin-bottom: 1rem;
    }

    @media (min-width: 992px) {
      min-width: 300px;
    }

    .nav-item {
      .nav-link {
        --bs-nav-link-color: rgba(var(--bs-body-color-rgb), 0.6);
        --bs-nav-pills-link-bg: transparent;
        --bs-nav-pills-link-disabled-color: var(--bs-nav-link-color);
        --bs-nav-pills-link-disabled-bg: transparent;
        --bs-nav-pills-link-hover-color: var(--bs-primary);
        --bs-nav-pills-link-hover-bg: transparent;
        --bs-nav-pills-link-active-color: var(--bs-primary);
        --bs-nav-pills-link-active-bg: transparent;
        --bs-nav-link-font-weight: 500;

        &[disabled] {
          opacity: 0.45;
        }

        display: flex;
        gap: 0.5rem;
        align-items: center;
        width: 100%;
        text-align: left;

        .nav-indicator {
          background-color: var(--bs-secondary);
          color: var(--bs-secondary-contrast);
          border-radius: var(--bs-border-radius);
          padding: 0.5rem;
          min-width: 35px;
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (min-width: 768px) {
            min-width: 45px;
            min-height: 45px;
          }
        }

        &:hover {
          .nav-indicator {
            color: var(--bs-primary);
          }
        }

        &.active {
          .nav-indicator {
            background-color: var(--bs-primary);
            color: var(--bs-primary-contrast);
            box-shadow: 0 0.125rem 0.25rem 0 rgba(var(--bs-primary-rgb), 0.4);
          }
        }
      }
    }
  }
  .tab-content-container {
    width: 100%;

    .tab-content {
      .card {
        .card-body {
          padding: 24px;
        }

        .table {
          margin-bottom: 0;
        }
      }
    }

    .list-group {
      img {
        max-width: 100%;
      }
    }
  }
}

.card-with-tabs {
  --bs-card-cap-padding-y: 0;
  --bs-card-cap-padding-x: 0;

  .nav-tabs {
    --bs-nav-tabs-border-radius: 0;
    --bs-nav-tabs-border-width: 0;
    --bs-nav-tabs-link-active-bg: transparent;
    --bs-nav-tabs-link-active-color: var(--bs-primary);
    --bs-nav-link-color: var(--bs-body-color);
    --bs-nav-link-hover-color: var(--bs-primary);

    .nav-link {
      &.active {
        box-shadow: 0 -2px 0 var(--bs-primary) inset;
      }
    }
  }
}

.text-bg-secondary {
  color: var(--bs-secondary-contrast) !important;
}

.offcanvas {
  &.menu-collapsed {
    .offcanvas-body {
      .menu-link {
        width: 52px !important;

        .menu-link-text {
          opacity: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &:not(.collapsing-animation) {
      &:hover {
        .offcanvas-body {
          .menu-link {
            width: 213px !important;

            .menu-link-text {
              opacity: 1;
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
.accordion {
  --bs-accordion-bg: var(--bs-box-bg);
  --bs-accordion-color: var(--bs-box-color);
  --bs-accordion-active-bg: transparent;
  --bs-accordion-border-color: var(--bs-body-bg);
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-focus-box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);

  & > .card.accordion-item {
    margin-bottom: 0.5rem;
    border-radius: 0.375rem;
  }
}

.modal-dialog {
  .modal-footer {
    justify-content: space-between;
  }
}
.modal {
  .modal-xxl {
    --bs-modal-width: 1200px;
  }
}

.enquiry-business {
  --bs-table-bg: #fffea6;
  --bs-table-color: #000;
}
.enquiry-commercial {
  --bs-table-bg: #b1cf95;
  --bs-table-color: #000;
}
.enquiry-cargo {
  --bs-table-bg: #93a9d7;
  --bs-table-color: #000;
}
.enquiry-sport {
  --bs-table-bg: #ebb38a;
  --bs-table-color: #000;
}
.enquiry-helico {
  --bs-table-bg: #f9665a;
  --bs-table-color: #000;
}

.enquiry-acmi {
  --bs-table-bg: #cbc3e3;
  --bs-table-color: #000;
}

.alert {
  &.alert-sm {
    padding: 6px;
  }
}
